import axios from 'axios';
import store from '../store';

export default {
    getKamInfo: () => axios.get('kams/info'),
    
    showCustomer: (id) => axios.get('kams/customers/'+id+'/show'),
    getProductCustomerList: (id,filters) => axios.post('kams/customers/'+id+'/products',filters),
    getStoreCustomerList: (id) => axios.get('kams/customers/'+id+'/stores'),
    storePromotionCustomer: (id,promotion) => axios.post('kams/customers/stores/promotion/update',{id: id, promotion: promotion}),

    getCustomerList: (filters) => axios.post('kams/customers',filters),
    getRutaList: (filters) => axios.post('kams/routes',filters),
    getUniversoList: (filters) => axios.post('kams/universes',filters),
    getGenericList: (filters) => axios.post('kams/generic-routes',filters),    
    homeVisitsMetrics: (filters) => axios.post('kams/visits/metrics',filters),

    //Rutas
    showCollaborator: (id) => axios.get('kams/collaborators/'+id+'/show'),
    collaboratorVisitBrands: (id, filters) => axios.post('kams/collaborators/'+id+'/visit-brands',filters),
    collaboratorVisits: (id, filters) => axios.post('kams/collaborators/'+id+'/visits',filters),
    collaboratorVisitsMetrics: (id, filters) => axios.post('kams/collaborators/'+id+'/metrics',filters),
    downloadExcel: (id, filters) => axios.post('kams/collaborators/'+id+'/download-excel', filters).then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    getBrands: () => axios.get('kams/brands/simpleList'),

    //Universos
    customerVisits: (id, filters) => axios.post('kams/customers/'+id+'/visits', filters),
    customerVisitsMetrics: (id, filters) => axios.post('kams/customers/'+id+'/metrics', filters),
    downloadExcelCustomerVisits: (id, filters) => axios.post('kams/customers/'+id+'/download-excel', filters).then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),

    //Visitas
    getVisit: (visit_id) => axios.post('kams/operation/visit', {visit_id: visit_id}),
    getProductsVisit: (visit_id, product_ids) => axios.post('kams/operation/products-visit', {visit_id: visit_id, product_ids: product_ids}),
    setCheckOut: (visit_id, comment) => axios.post('kams/operation/checkout', {visit_id: visit_id, comment: comment}),    
    setReject: (visit_id, comment) => axios.post('kams/operation/reject', {visit_id: visit_id, comment: comment}),    
    
}