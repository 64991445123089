<template>
    <CModal title="Asignar Colaborador" :color="colorModal" :show.sync="collaboratorModal">
        <p><span style="font-weight: bold;">{{visit.branch_name}}</span><span style="float: right;">{{visit.date | date}}</span><br />{{visit.store_name}}</p>
        <p style="font-weight: bold;">¿Quién será el colaborador que realice la visita? </p>
        <select v-model="collaborator_id" style="float: left; padding: 2px; width: 100%">
          <option v-for="collaborator in collaborators" :value="collaborator.id">{{collaborator.name}}</option>
        </select>
        <br />
        <template #header>
          <h5 class="modal-title">Asignar Colaborador</h5>
          <CButtonClose @click="collaboratorModal = false" class="text-white"/>
        </template>
        <template #footer>
          <CButton @click="collaboratorModal = false" color="secondary">Cancelar</CButton>
          <CButton @click="updateCollaborator" color="success">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>

import store from '../../../store'
import ws from '../../../services/admin';

export default {
  name: 'ChangeCollaboratorModal',
  props: {

  },
  data () {
    return {
      collaboratorModal: false,
      colorModal: 'info',
      visit: false,
      collaborator_id: "",
      collaborators: []
    }
  },
  methods: {
    show (collaborators, visit) {
      this.collaboratorModal = true;
      this.collaborators = collaborators;
      this.collaborator_id = visit.collaborator_id;
      this.visit = visit;
    },
    async updateCollaborator(){
      this.loading();

      let response = await ws.changeCollaborator(this.visit.id, this.collaborator_id); 

      if(response.type == "success"){
        this.$emit("update", response.data );
      }
      else{
        this.$emit("update", false );
      }

      this.showToast(response.type, response.message);

      this.loaded();
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    hide () {
      this.collaboratorModal = false;
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>