<template>
    <CModal title="Nueva Sucursal" :color="colorModal" :show.sync="addBranchModal">
        <p style="font-weight: bold;">Tienda (Cadena): </p>
        <select v-model="store_id" style="float: left; padding: 2px; width: 100%" @change="changeStore">
          <option v-for="store in stores" :value="store.id">{{store.name}}</option>
        </select>
        <br /><br /><br />
        <p style="float: right; font-weight: bold;">Sucursal: </p>
        <br /><br />
        <select v-model="branch_id" style="float: right; padding: 2px;">
          <option v-for="branch in branchs" :value="branch.id">{{branch.name}}</option>
        </select>
        <template #header>
          <h5 class="modal-title">Nueva Sucursal</h5>
          <CButtonClose @click="addBranchModal = false" class="text-white"/>
        </template>
        <template #footer>
          <CButton @click="addBranchModal = false" color="secondary">Cancelar</CButton>
          <CButton @click="addBranch" color="success">Registrar</CButton>
        </template>
    </CModal>
</template>

<script>

import store from '../../../store'
import ws from '../../../services/admin';

export default {
  name: 'AddBranchModal',
  props: {

  },
  data () {
    return {
      addBranchModal: false,
      colorModal: 'info',
      contract_id: 0,
      store_id: 0,
      branch_id: 0,
      stores: [],
      branchs: []
    }
  },
  methods: {
    async show (contract_id, stores) {
      this.addBranchModal = true;
      this.contract_id = contract_id;
      this.stores = stores;

      this.store_id = this.stores[0].id+"";

      this.loading();

      let response = await ws.getStoreBranchs(this.contract_id, this.store_id); 

      if(response.type == "success"){
        this.branchs = response.data;

        this.branch_id = this.branchs[0].id+"";
      }

      this.loaded();
    },
    async changeStore(){
      this.loading();

      let response = await ws.getStoreBranchs(this.contract_id, this.store_id); 

      if(response.type == "success"){
        this.branchs = response.data;

        this.branch_id = this.branchs[0].id+"";
      }

      this.loaded();
    },
    addBranch(){
      this.$emit("adding", {store_id: this.store_id, branch_id: this.branch_id} );
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    hide () {
      this.addBranchModal = false;
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>