<template>
    <CModal title="Clonar Visitas" :color="colorModal" :show.sync="cloneModal">
        <p style="font-weight: bold;">Clonar desde la semana: </p>
        <select v-model="source_week" style="float: left; padding: 2px;">
          <option v-for="current in weeks" :value="current.week_number" v-if="current.week_number < current_week || (current.week_number == current_week && current.week_number != target_week)">{{current.week_name}}</option>
        </select>
        <br /><br /><br />
        <p style="float: right; font-weight: bold;">Clonar en la semana: </p>
        <br /><br />
        <select v-model="target_week" style="float: right; padding: 2px;">
          <option v-for="current in weeks" :value="current.week_number" v-if="current.week_number > current_week || (current.week_number == current_week && current.week_number != source_week)">{{current.week_name}}</option>
        </select>
        <template #header>
          <h5 class="modal-title">Clonar Visitas</h5>
          <CButtonClose @click="cloneModal = false" class="text-white"/>
        </template>
        <template #footer>
          <CButton @click="cloneModal = false" color="secondary">Cancelar</CButton>
          <CButton @click="cloning" color="success">Clonar</CButton>
        </template>
    </CModal>
</template>

<script>

import store from '../../../store'
import ws from '../../../services/kam';

export default {
  name: 'CloneModal',
  props: {

  },
  data () {
    return {
      cloneModal: false,
      colorModal: 'info',
      current_week: "8",
      source_week: "8",
      target_week: "11",
      weeks: []
    }
  },
  methods: {
    show (weeks, week, current_week) {
      this.cloneModal = true;
      this.weeks = weeks;
      this.current_week = current_week;
      this.source_week = week*1;
      this.target_week = current_week*1 + 1;
    },
    cloning(){
      this.$emit("cloning", {source_week: this.source_week, target_week: this.target_week} );
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    hide () {
      this.cloneModal = false;
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>