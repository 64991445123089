<template>
  <div>
    <div class="row">
      <div class="col-12" style="padding: 0px 15px;">
        <div style="float: right;" v-if="tab == 'visits'">
          <select v-model="week" style="float: right; padding: 2px;" @change="loadWeekVisits()">
            <option v-for="current in weeks" :value="current.week_number">{{current.week_name}}</option>
          </select>
          <br /><br />
          <CButton color="info" size="sm" @click="cloneModal()" style="float: right;">Clonar Visitas</CButton>
        </div>
        <div style="float: right;" v-if="tab == 'branchs'">
          <CButton color="info" size="sm" @click="addBranchModal()" style="float: right;">Nueva Sucursal</CButton>
        </div>
        <h5 v-if="tab == 'stores'">Tiendas</h5>
        <h5 v-if="tab == 'branchs'">Sucursales</h5>
        <h5 v-if="tab == 'products'">Productos</h5>
        <h5 v-if="tab == 'collaborators'">Colaboradores</h5>
        <h5 v-if="tab == 'visits'">Visitas de la Semana</h5>
      </div>
    </div>
    <br />
    <CCard>
    <CCardHeader>
      <slot name="header">
        <ul class="nav nav-tabs justify-content-begin">
          <li @click="changeTab('stores')" class="nav-item">
            <a class="nav-link" :class="tab == 'stores' ? 'active' : ''" aria-current="page">Cadenas</a>
          </li>
          <li @click="changeTab('branchs')" class="nav-item">
            <a class="nav-link" :class="tab == 'branchs' ? 'active' : ''">Sucursales</a>
          </li>
          <li @click="changeTab('products')" class="nav-item">
            <a class="nav-link" :class="tab == 'products' ? 'active' : ''">Productos</a>
          </li>
          <li @click="changeTab('collaborators')" class="nav-item">
            <a class="nav-link" :class="tab == 'collaborators' ? 'active' : ''">Colaboradores</a>
          </li>
          <li @click="changeTab('visits')" class="nav-item">
            <a class="nav-link" :class="tab == 'visits' ? 'active' : ''">Visitas</a>
          </li>
        </ul>
      </slot>
    </CCardHeader>
    <CCardBody style="padding: 0 1.25rem 1.25rem 1.25rem">
        <br />
        <div v-if="tab == 'stores'">
          <CDataTable
            :hover="true"
            :striped="true"
            :border="true"
            :small="true"
            :fixed="true"
            :items="contract_stores"
            :fields="contract_store_fields"
            :items-per-page="10"
            :dark="false"
            pagination
          >
            <template #name="{item}"><td>{{item.name}}</td></template>
            <template #actions="{item}">
              <td class="table_actions b3">
                -
              </td>
            </template>
          </CDataTable>
        </div>
        <div v-if="tab == 'branchs'">
          <CDataTable
            :hover="true"
            :striped="true"
            :border="true"
            :small="true"
            :fixed="true"
            :items="contract_branchs"
            :fields="contract_branch_fields"
            :items-per-page="10"
            :dark="false"
            pagination
          >
            <template #date="{item}"><td class="w100 text-center">{{item.date | date}}</td></template>
            <template #customer_name="{item}"><td>{{item.customer_name}}</td></template>
            <template #description="{item}"><td>{{item.description}}</td></template>
            <template #actions="{item}">
              <td class="table_actions b3">
                -
              </td>
            </template>
          </CDataTable>
        </div>
        <div v-if="tab == 'products'">
          <CDataTable
            :hover="true"
            :striped="true"
            :border="true"
            :small="true"
            :fixed="true"
            :items="contract_products"
            :fields="contract_product_fields"
            :items-per-page="10"
            :dark="false"
            pagination
          >
            <template #name="{item}"><td>{{item.name}}</td></template>
            <template #actions="{item}">
              <td class="table_actions b3">
                -
              </td>
            </template>
          </CDataTable>
        </div>
        <div v-if="tab == 'collaborators'">
          <CDataTable
            :hover="true"
            :striped="true"
            :border="true"
            :small="true"
            :fixed="true"
            :items="contract_collaborators"
            :fields="contract_collaborator_fields"
            :items-per-page="10"
            :dark="false"
            pagination
          >
            <template #name="{item}"><td>{{item.name}}</td></template>
            <template #actions="{item}">
              <td class="table_actions b3">
                -
              </td>
            </template>
          </CDataTable>
        </div>
        <div v-if="tab == 'visits'">
          <div class="row" style="padding: 0px 15px;">
            <div class="col-2" v-for="week_day in week_days" :style="'border: 0px solid; padding: 10px; font-size: 10px; background-color: '+week_day.color">
              <span style="font-size: 12px; font-weight: bold;">{{week_day.name}}<hr /></span>
              <draggable
                class="list-group"
                tag="ul"
                style="height: 100%;"
                v-model="visits_lists[week_day.id]"
                v-bind="dragOptions"
                @start="isDragging = true"
                @end="isDragging = false"
                @change="finish"
                @remove="remove($event, week_day.id, visits_lists[week_day.id])"
                @add="add($event, week_day.id, visits_lists[week_day.id])"
              >
                <transition-group type="transition" name="flip-list">
                  <li
                    class="list-group-item"
                    v-for="element in visits_lists[week_day.id]"
                    :key="element.id"
                    @click="changeCollaboratorModal(element)"
                  >
                    <i
                      :class="
                        element.fixed ? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'
                      "
                      @click="element.fixed = !element.fixed"
                      aria-hidden="true"
                    ></i>
                    <CIcon name="cilCheckCircle" style="width: 20px; height: 20px; float: right; margin-top: 5px; color: green" v-if="element.status == 1 && element.is_validated == 1"/>
                    <CIcon name="cilCheckCircle" style="width: 20px; height: 20px; float: right; margin-top: 5px; color: gray" v-if="element.status == 1 && element.is_validated == 0"/>
                    <CIcon name="cilCircle" style="width: 20px; height: 20px; float: right; margin-top: 5px; color: gray" v-if="element.status == 0"/>
                    <CIcon name="cilXCircle" style="width: 20px; height: 20px; float: right; margin-top: 5px; color: red" v-if="element.status == 1 && element.is_validated == -1"/>
                    <b>{{ element.branch_name }}</b><br />
                    {{ element.store_name }}<br /><br />
                    <span style="font-style: italic; color: gray;font-weight: bold;">{{ element.collaborator_id > 0 ? element.collaborator_name : 'NO ASIGNADA' }}</span>
                  </li>
                </transition-group>
              </draggable>
            </div>
          </div>
          <br/>
          <div class="row" style="padding: 0px 15px;">
            <div class="col-12" :style="'border: 0px solid; padding: 10px; font-size: 10px; background-color: lightgray'">
              <span style="font-size: 12px; font-weight: bold;">Pendientes<hr /></span>
              <draggable
                class="list-group col-3"
                tag="ul"
                style="height: 100%;"
                v-model="available_visits"
                v-bind="dragOptions"
                @start="isDragging = true"
                @end="isDragging = false"
                @change="finish"
                @remove="remove($event, 0, 0)"
                @add="add($event, 0, 0)"
              >
                <transition-group type="transition" name="flip-list">
                  <li
                    class="list-group-item"
                    v-for="element in available_visits"
                    :key="element.branch_id"
                  >
                    <i
                      :class="
                        element.fixed ? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'
                      "
                      @click="element.fixed = !element.fixed"
                      aria-hidden="true"
                    ></i>
                    <b>{{ element.branch_name }}</b><br />
                    {{ element.store_name }}<br /><br />
                    <span style="font-style: italic; color: gray;font-weight: bold;">NO ASIGNADA</span>
                  </li>
                </transition-group>
              </draggable>
            </div>
          </div>
        </div>
    </CCardBody>
  </CCard>

  <AddBranchModal ref="addBranchModal" @adding="addBranchProcess"></AddBranchModal>
  <CloneModal ref="cloneModal" @cloning="cloneProcess"></CloneModal>
  <ChangeCollaboratorModal ref="collaboratorModal" @update="changeCollaboratorProcess"></ChangeCollaboratorModal>
  </div>
</template>
<script>

import store from '../../store'
import router from '../../router/index'
import ws from '../../services/admin';
import moment from 'moment';

import draggable from 'vuedraggable'

import CloneModal from './modals/CloneModal.vue';
import AddBranchModal from './modals/AddBranchModal.vue';
import ChangeCollaboratorModal from './modals/ChangeCollaboratorModal.vue';

export default {
  name: 'AdminContract',
  components: {
    draggable,
    CloneModal,
    ChangeCollaboratorModal,
    AddBranchModal
  },
  data () {
    return {
      id: 0,
      tab: 'visits',
      enabled: true,
      current_week: "7",
      week: "7",
      contract_id: 0,
      weeks: [],
      week_days: [
        {id: 1, name: "Lunes", color: "#FBB9C5"},
        {id: 2, name: "Martes", color: "#FDD0B1"},
        {id: 3, name: "Miercoles", color: "#F9EFC7"},
        {id: 4, name: "Jueves", color: "#C3EDBF"},
        {id: 5, name: "Viernes", color: "#B8DFE6"},
        {id: 6, name: "Sábado", color: "#C5BBDE"},
      ],
      visits_lists: [],
      available_visits: [],
      dragging: false,
      item: false,
      adding: -1,
      removing: -1,
      contract_stores: [],
      contract_store_fields: [],
      contract_branchs: [],
      contract_branch_fields: [],
      contract_products: [],
      contract_product_fields: [],
      contract_collaborators: [],
      contract_collaborator_fields: []
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  mounted: async function() {
    this.loading();

    let is_administrator = localStorage.getItem("is_administrator");

    if(is_administrator == "false"){
      let is_kam = localStorage.getItem("is_kam");

      if(is_kam == "false"){
        window.location.href = "/#/home"; this.loaded(); return;
      }
      else{
        window.location.href = "/#/welcome"; this.loaded(); return;
      }
    }

    this.id = await this.$route.params.id;
    this.tab = await this.$route.params.tab;

    switch(this.tab){
      case "stores": await this.loadStores(); break;
      case "branchs": await this.loadBranchs(); await this.loadStores(); break;
      case "products": await this.loadProducts(); break;
      case "collaborators": await this.loadCollaborators(); break;
      case "visits": await this.loadVisits(); break;
    }

    this.loaded();
  },
  methods: {
    changeTab (tab) {
      if(this.tab != tab){
        this.loading();

        this.$router.push("/contract/"+this.id+"/"+tab);
        //window.location.href = "/#/contract/"+this.id+"/"+tab; return;
        this.tab = tab;

        this.loaded();
      }
    },
    async loadStores () {
      this.loading();

      let response = await ws.getStores(this.id); 

      if(response.type == "success"){
        this.contract_stores = response.data;
        this.contract_store_fields = response.fields;
      }

      this.loaded();
    },
    async loadBranchs () {
      this.loading();

      let response = await ws.getBranchs(this.id); 

      if(response.type == "success"){
        this.contract_branchs = response.data;
        this.contract_branch_fields = response.fields;
      }

      this.loaded();
    },
    async loadProducts () {
      this.loading();

      let response = await ws.getProducts(this.id); 

      if(response.type == "success"){
        this.contract_products = response.data;
        this.contract_product_fields = response.fields;
      }

      this.loaded();
    },
    async loadCollaborators () {
      this.loading();

      console.log(this.contract_collaborators.length);
      console.log(this.contract_collaborators);

      if(this.contract_collaborators.length == 0){
        let response = await ws.getCollaborators(this.id); 

        if(response.type == "success"){
          this.contract_collaborators = response.data;
          this.contract_collaborator_fields = response.fields;
        }
      }

      this.loaded();
    },
    async loadVisits () {
      this.loading();

      let response = await ws.getWeeks(); 

      if(response.type == "success"){
        this.current_week = response.data.week+"";
        this.week = response.data.week+"";
        this.weeks = response.data.weeks;
      }

      let response2 = await ws.getVisits(this.id, this.week); 

      if(response2.type == "success"){
        this.visits_lists = response2.data;
      }

      let response3 = await ws.getCollaborators(this.id); 

      if(response3.type == "success"){
        this.contract_collaborators = response3.data;
      }

      let response4 = await ws.getAvailableVisits(this.id, this.week); 

      if(response4.type == "success"){
        this.available_visits = response4.data;
      }

      this.loaded();
    },
    async showContractStores (item) {
      this.loading();

      window.location.href = "/#/contract/"+item.id+"/show"; return;

      this.loaded();
    },
    async showContractVisits (item) {
      this.loading();

      window.location.href = "/#/contract/"+this.id+"/visits"; return;

      this.loaded();
    },
    async loadWeekVisits() {
      this.loading();

      let response = await ws.getVisits(this.id, this.week); 

      if(response.type == "success"){
        this.visits_lists = response.data;
      }

      this.loaded();
    },
    async addBranchModal() {
      this.loading();
      this.$refs.addBranchModal.show(this.id, this.contract_stores);
      this.loaded();
    },
    async addBranchProcess(data) {
      this.loading();

      let response = await ws.addBranch(this.id, data.store_id, data.branch_id); 

      if(response.type == "success"){
        this.$refs.addBranchModal.hide();
        this.loadBranchs();
      }

      this.showToast(response.type, response.message);

      this.loaded();
    },
    async cloneModal() {
      this.loading();
      this.$refs.cloneModal.show(this.weeks, this.week, this.current_week);
      this.loaded();
    },
    async cloneProcess(data) {
      this.loading();

      let response = await ws.cloneVisits(this.id, data.source_week, data.target_week); 

      if(response.type == "success"){
        this.$refs.cloneModal.hide();
        
        this.week = data.target_week;
        this.loadWeekVisits();
      }

      this.showToast(response.type, response.message);

      this.loaded();
    },
    changeCollaboratorModal(item) {
      this.loading();
      this.$refs.collaboratorModal.show(this.contract_collaborators, item);
      this.item = item;
      this.loaded();
    },
    changeCollaboratorProcess(visit) {
      console.log(visit);
      this.item.collaborator_id = visit.collaborator_id;
      this.item.collaborator_name = visit.collaborator_name;
      
      this.$refs.collaboratorModal.hide();
    },
    async finish(item) {
      let _this = this;

      _this.loading();

      let type = "update";

      setTimeout(async function(){
        let visit_id = 0;

        setTimeout(async function(){
          if(item.added != undefined){
            _this.item = item.added;
            visit_id = item.added.element.id;

            let element = item.added.element;

            if(visit_id > 0){
              if(_this.adding > 0){
                let response = await ws.changeVisit(visit_id, _this.adding); 

                if(response.type == "success"){
                  this.visits_lists = response.data;
                }

                _this.showToast(response.type, response.message);
              }
              else{
                if(_this.adding == 0){
                  let response = await ws.changeVisit(visit_id, _this.adding); 

                  if(response.type == "success"){
                    this.visits_lists = response.data;
                  }

                  _this.showToast(response.type, response.message);

                  element.id = 0;
                  element.collaborator_id = 0;
                  element.collaborator_name = "";
                }
              }
            }

            _this.loaded();
          }
          else{
            if(item.removed != undefined){
              _this.item = item.removed;
              visit_id = item.removed.element.id;

              let element = item.removed.element;

              if(visit_id > 0){

              }
              else{
                if(item.removed.element !== undefined){
                  let element = item.removed.element;

                  let response = await ws.addVisit(_this.id, element.store_id, element.branch_id, _this.week, _this.adding); 

                  if(response.type == "success"){
                    let data = response.data;
                    
                    element.id = data.id;
                  }

                  _this.showToast(response.type, response.message);  
                }
              }

              _this.loaded();
            }
          }

          this.dragging = false;
          this.item = false;
          this.adding = -1;
          this.removing = -1;

        }, 500);
      }, 500)
    },
    add(event, day) {
      let _this = this;

      this.adding = day;
    },
    remove(event, remove_day) {
      this.removing = remove_day;
    },
    showToast(type, message){
        store.commit('toast', {color: type, message: message});
    },
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
<style>
.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
  padding: 5px 10px 5px 15px !important;
  background: #eeeeee;
}

.list-group-item i {
  cursor: pointer;
}
</style>